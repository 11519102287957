import { Container, Typography } from "@mui/material";
import React from "react";

const Footer: React.FC = () => {
  return (
    <Container sx={styles.footer}>
      <Typography variant="h4" align="center" style={styles.footerText}>
        {`Copyright © 2023 Yangtze Qù by Tropitel Group.`}
      </Typography>
      <Typography variant="h4" align="center" style={styles.footerText}>
        {`All rights reserved`}
      </Typography>
    </Container>
  );
};

const styles = {
  footer: {
    // textAlign: "center", // Center align the content
    // position: "absolute",
    // left: 0,
    // bottom: 0,
    // right: 0,
    // paddingBottom: "30px",
    marginTop: "35px",
    marginBottom: "35px",
  },
  footerText: {
    color: "rgba(255, 255, 255, 0.22)",
    fontSize: "14px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    lineHeight: "150%",
  },
};

export default Footer;
