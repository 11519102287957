// actions
const SET = "app/SET";
const SET_VIEW = "app/SET_VIEW";
const SET_BOOKING = "app/SET_BOOKING";

const OPEN_SNACKBAR = "app/OPEN_SNACKBAR";
const CLOSE_SNACKBAR = "app/CLOSE_MODAL";

const DEFAULT_STATE = {
  listing: [],
  view: "bookingForm", // bookingForm, bookingOTP, bookingStatus
  booking: null,

  snackBarOpen: false,
  snackBarContent: null,
  snackBarSeverity: "info",
  snackBarPersist: false,
};

// reducer
const reducer = (state = DEFAULT_STATE, action: any = {}) => {
  switch (action.type) {
    case SET:
      return Object.assign({}, state, { listing: action.listing });
    case SET_VIEW:
      return Object.assign({}, state, { view: action.view });
    case SET_BOOKING:
      return Object.assign({}, state, { booking: action.booking });

    case OPEN_SNACKBAR:
      return Object.assign({}, state, {
        snackBarOpen: true,
        snackBarContent: action.snackBarContent,
        snackBarSeverity: action.snackBarSeverity,
        snackBarPersist: action.persist ? action.persist : false,
      });
    case CLOSE_SNACKBAR:
      return Object.assign({}, state, {
        snackBarOpen: false,
        snackBarContent: null,
        snackBarSeverity: "info",
      });
    default:
      return state;
  }
};

export default reducer;

// action creators
export const setListing = (listing: any[]) => ({ listing, type: SET });
export const setView = (view: string) => ({ view, type: SET_VIEW });
export const setBooking = (booking: any) => ({ booking, type: SET_BOOKING });

export const openSnackBar = (
  snackBarContent: string,
  snackBarSeverity: string,
  persist?: boolean
) => ({ snackBarContent, snackBarSeverity, persist, type: OPEN_SNACKBAR });

export const closeSnackBar = () => ({
  snackBarContent: null,
  type: CLOSE_SNACKBAR,
});
