// BookingOTP.js
import { Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import useApp from "../../hooks/useApp";
import CreateBookingService from "../../services/CreateBookingService";
import { sendOtpViaSMS, verifyOtp } from "../../services/OtpServices";
import { setView } from "../../store/ducks/app";
import Footer from "../Footer/Footer";
import ButtonLoader from "../UI/ButtonLoader";
import "./BookingOTP.css";

interface Props {
  phoneCode: string;
}

const BookingOTP: React.FC<Props> = ({ phoneCode }) => {
  const dispatch = useDispatch();
  const { showError } = useApp();

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [checkCode, setCheckCode] = useState("");
  const [sendCodeLoading, setSendCodeLoading] = useState(false);

  const [phone, setPhone] = useState("");
  const booking = useSelector((state: any) => state.app.booking);

  useEffect(() => {
    if (booking) {
      setPhone(booking.customerPhone);
      handleSendCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  const handleSendCode = async () => {
    if (!sendCodeLoading) {
      const phoneNumber = booking ? booking.customerPhone : phone;
      const lastRequest: any = await localStorage.getItem("otpSend");
      const timeNow = new Date().getTime() + "";

      try {
        if (parseInt(timeNow) - parseInt(lastRequest) < 60 * 1000) {
          showError("Kindly wait 60 seconds before sending another request");
          return;
        }

        setSendCodeLoading(true);

        await localStorage.setItem("otpSend", new Date().getTime() + "");
        const response = await sendOtpViaSMS(
          phoneCode +
            (phoneCode === "+20" && phoneNumber.slice(0, 1) === "0"
              ? phoneNumber.slice(1, phoneNumber.length)
              : phoneNumber)
        );

        setCheckCode(response.checkCode);
      } catch (error) {
        console.error("Error sending OTP:", error);
        showError("Failed to send OTP, Please try again.");
      }
      setSendCodeLoading(false);
    }
  };

  const verifyOTP = async () => {
    try {
      setLoading(true);

      const verify = await verifyOtp(otp, checkCode);

      const otpStatus = verify.data.otpPasscodeStatus;

      if (otpStatus === 0) {
        showError("OTP Expired, please try again with the new OTP.");
      } else if (otpStatus === 2) {
        showError("OTP wrong, please try again.");
      } else if (otpStatus === 1) {
        // Create booking
        await submitBooking();
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      if (error.response.data.replyCode === 2001) {
        showError("You have exceeded maximum number of trials.");
      } else {
        showError(error);
      }
    }
    setLoading(false);
  };

  const submitBooking = async () => {
    try {
      if (booking) {
        const createdBooking = await CreateBookingService(booking);
        // console.log({ createdBooking });
        // Create Booking Comments
        // Navigate to booking status page
        dispatch(setView("bookingStatus"));
      }
    } catch (error: any) {
      console.log({ error });
      showError("Failed to reserve table, Please try again.");
    }
  };

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          width: "90%",
          marginTop: "100px",
          zIndex: "1",
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Typography
            sx={{
              fontSize: 16,
              color: "#ccc",
              textAlign: "center",
              marginBottom: "1rem",
              fontWeight: 400,
              // zIndex: 10,
            }}
            variant="h6"
            className="typography"
          >
            Kindly enter the verification code (OTP) <br></br> sent to{" "}
            {phoneCode +
              (phoneCode === "+20" && phone.slice(0, 1) === "0"
                ? phone.slice(1, phone.length)
                : phone)}
          </Typography>
          <Grid
            container
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <OtpInput
              shouldAutoFocus={true}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              containerStyle={{
                alignItems: "center",
                justifyContent: "center",
              }}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} className="input" />}
            />
          </Grid>

          <Typography
            sx={{
              fontSize: 14,
              color: "#aaa",
              textAlign: "center",
              marginTop: "1rem",
              fontWeight: 400,
              // zIndex: 10,
            }}
            variant="h6"
          >
            Didn't receive a code ?{"  "}
            <span
              style={{ color: "#DACAA7", cursor: "pointer" }}
              onClick={handleSendCode}
            >
              resend.
            </span>
          </Typography>

          <Grid
            container
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              size="small"
              disableElevation
              endIcon={loading && <ButtonLoader />}
              disabled={loading}
              style={{ ...styles.submitButton, marginRight: "10px" }}
              onClick={() => verifyOTP()}
            >
              Verify
            </Button>

            <Button
              type="submit"
              variant="contained"
              size="small"
              disableElevation
              // endIcon={loading && <ButtonLoader />}
              disabled={loading}
              style={styles.submitButton}
              onClick={() => {
                dispatch(setView("bookingForm"));
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>

        <Footer />
      </Container>
    </>
  );
};

const styles = {
  submitButton: {
    minHeight: "50px",
    minWidth: "100px",
    marginTop: "1vh",
    marginBottom: "3vh",
    borderRadius: "0px",
    border: "0.3px solid #dacaa7",
    background:
      "linear-gradient(91deg, #5E273A -4.95%, rgba(170, 52, 13, 0.00) 105.66%)",
  },
};

export default BookingOTP;
