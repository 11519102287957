import "./BookingComponent.css";
import { Grid, Hidden } from "@mui/material";
import { useSelector } from "react-redux";

import BookingStatus from "./BookingStatus";
import SplashScreen from "../SplashScreen/SplashScreen";
import BookingForm from "./BookingForm";
import BookingOTP from "../BookingOTP/BookingOTP";
import { Screens } from "../../constants/enums";
import useVenue from "../../hooks/useAllVenues";

const BookingComponent = () => {
  const imageHeight = 1286;
  const screenHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );

  const { venue, loading } = useVenue();
  const view: string = useSelector((state: any) => state.app.view);

  return (
    <>
      <div className="Container">
        {/* Show splash screen on mobile screens only! */}
        <Hidden mdUp>
          <SplashScreen />
        </Hidden>

        <Grid container justifyContent="center" alignItems="center">
          {/* First Column (Hidden in Mobile) */}
          <Hidden smDown>
            <Grid item md={5} lg={4}>
              <img
                src={require("../../assets/background-logo.png")}
                alt="restaurant"
                style={{
                  flex: 1,
                  width: "100%",
                  minHeight: "725px",
                  height: imageHeight > screenHeight ? screenHeight : "100%",
                }}
                // onError={}
              />
            </Grid>
          </Hidden>

          {/* Second Column */}
          <Grid
            item
            xs={12}
            md={7}
            lg={8}
            style={{
              minHeight: "725px",
              height: imageHeight > screenHeight ? screenHeight : "100%",
            }}
          >
            <>
              {view === Screens.VIEW_BOOKING_FORM && (
                <BookingForm venue={venue} loading={loading}></BookingForm>
              )}
              {view === Screens.VIEW_BOOKING_OTP && (
                <BookingOTP phoneCode={"+20"}></BookingOTP>
              )}
              {view === Screens.VIEW_BOOKING_STATUS && (
                <BookingStatus
                  venue={venue}
                  icon={"pending"}
                  message={"THANK YOU FOR YOUR RESERVATION!"}
                  description={`Your booking is now reserved & will Let you know once confirmed`}
                ></BookingStatus>
              )}
            </>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default BookingComponent;
