import { useEffect, useState } from "react";
import { getTimeSlots, getVenues } from "../services/VenuesService";

const useVenue = () => {
  const [venue, setVenue] = useState<any>(null);
  const [AllVenues, setAllVenues] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const concepts = await getVenues();

      AddTimeSlots(concepts);
      setAllVenues(concepts);

      setLoading(false);
    } catch (error: any) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const AddTimeSlots = async (AllVenues: any) => {
    try {
      setLoading(true);
      const venue = AllVenues.length > 0 ? AllVenues[0] : null;

      const timeSlots = await getTimeSlots(venue.id);

      // Add the timeSlots result to the venue object
      const updatedVenue = {
        ...venue,
        timeSlots: timeSlots,
      };

      setVenue(updatedVenue);
      setTimeSlots(timeSlots);

      setLoading(false);
    } catch (error: any) {
      setError(error);
    }
  };

  return {
    venue,
    AllVenues,
    timeSlots,
    loading,
    error,
  };
};

export default useVenue;
