import { FC } from "react";
import { Box, CircularProgress } from "@mui/material";

const MainLoader: FC = (): JSX.Element => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "50vh",
    }}
  >
    <CircularProgress size={64} thickness={1} color={"secondary"} />
  </Box>
);

export default MainLoader;
