import { CacheProvider, EmotionCache } from "@emotion/react";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import createEmotionCache from "./helpers/createEmotionCache";
import "./index.css";
import store from "./store";
import lightThemeOptions from "./theme/lightThemeOptions";

let lightTheme = createTheme(lightThemeOptions);
lightTheme = responsiveFontSizes(lightTheme);
const emotionCache: EmotionCache = createEmotionCache();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={lightTheme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </CacheProvider>
  </Provider>
);
