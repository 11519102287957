// BookingStatus.js
import { Avatar, Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Separator from "../Separator/Separator";
import UpdateOutlinedIcon from "@mui/icons-material/UpdateOutlined";
import Footer from "../Footer/Footer";
import { useSelector } from "react-redux";
import { Booking } from "../../models/booking";
import { capitalizeFirstLetter, getTimeSlotName } from "../../helpers/utils";

interface Props {
  venue: any;
  icon?: string;
  message?: string;
  description?: string;
}

const BookingStatus: React.FC<Props> = ({
  venue,
  icon,
  message,
  description,
}) => {
  const timeSlots: any[] = venue ? venue.timeSlots : [];
  const booking: Booking | null = useSelector(
    (state: any) => state.app.booking
  );

  // console.log({
  //   booking,
  // });

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{
          width: "90%",
          marginTop: "100px",
        }}
      >
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {/* Account Logo Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              component={"img"}
              src={require("../../assets/tropitelLogo.png")}
              alt="logo"
              width={200}
            />
          </Box>

          {/* Status Section */}
          <Grid item xs={12} justifyContent="center" alignItems="center">
            <Avatar
              sx={{
                width: 100,
                height: 100,
                backgroundColor: "#382219", // Set your custom background color
                border: "2px solid #986840", // Set your border color
                textAlign: "center",
                marginTop: "40px",
                marginBottom: "40px",
                marginX: "auto", // Center horizontally
              }}
            >
              <UpdateOutlinedIcon fontSize="large" sx={{ color: "#DACAA7" }} />
            </Avatar>

            <Typography component="p" gutterBottom sx={styles.message}>
              {message}
            </Typography>
            <Typography component="p" gutterBottom sx={styles.description}>
              {description}
            </Typography>

            <Separator />
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              component="p"
              fontWeight={700}
              gutterBottom
              sx={styles.title}
            >
              {`BOOKING DETAILS:`}
            </Typography>
            {/* Details */}
            <Typography component="p" gutterBottom sx={styles.details}>
              <strong>Guest Name: </strong>
              {booking && booking.customerName
                ? capitalizeFirstLetter(booking.customerName)
                : ""}
            </Typography>
            <Typography component="p" gutterBottom sx={styles.details}>
              <strong>Mobile: </strong>
              {booking ? booking.customerPhone : ""}
            </Typography>
            <Typography component="p" sx={styles.details}>
              <strong>Email: </strong>
              {booking ? booking.email : ""}
            </Typography>
            <Typography component="p" sx={styles.details}>
              <strong>Pax No.: </strong>
              {booking ? booking.accompaniedCount : "1"}
            </Typography>
            <Typography component="p" sx={styles.details}>
              <strong>Date: </strong>
              {booking ? booking.date : ""}
            </Typography>
            <Typography component="p" sx={styles.details}>
              <strong>Time: </strong>
              {booking && booking.timeSlots && booking.timeSlots.length > 0
                ? getTimeSlotName(
                    timeSlots,
                    booking.timeSlots[0] ? booking.timeSlots[0] : ""
                  )
                : ""}
            </Typography>
          </Grid>
        </Grid>

        <Footer />
      </Container>
    </>
  );
};

const styles = {
  title: {
    color: "#DACAA7",
    fontFamily: "Proza Libre",
    fontSize: "14px",
    letterSpacing: "0.64px",
    textTransform: "uppercase",
  },
  details: {
    color: "rgba(255, 255, 255, 0.55)",
    fontFamily: "Proza Libre",
    fontSize: "14px",
    letterSpacing: "0.64px",
  },
  message: {
    color: "#DACAA7",
    textAlign: "center",
    fontFamily: "Proza Libre",
    fontSize: "16px",
    letterSpacing: "0.64px",
    textTransform: "uppercase",
  },
  description: {
    color: "#5C4F3F",
    textAlign: "center",
    fontFamily: "Proza Libre",
    fontSize: "14px",
    letterSpacing: "0.56px",
    textTransform: "uppercase",
  },
};

export default BookingStatus;
