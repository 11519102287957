import * as Yup from "yup";
import { Pages } from "../constants/enums";

const useFormValidations = (slug: string) => {
  const phoneRegExp =
    /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

  // const phoneRegExp = /^[0-9+]*$/;
  const bookingValidation = () =>
    Yup.object().shape({
      pax: Yup.string().required("PAX is required").min(0),
      date: Yup.string().required("Date is required"),
      time: Yup.string().required("Time is required"),
      area: Yup.string().required("Area is required"),

      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      mobile: Yup.string()
        .matches(phoneRegExp, "Phone number is not valid")
        .required("Phone number is required"),
    });

  const getValidationSchema = () => {
    switch (slug) {
      case Pages.BOOKING:
        return bookingValidation();

      default:
        return Yup.object();
    }
  };

  return {
    getValidationSchema,
  };
};

export default useFormValidations;
