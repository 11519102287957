import useLocalization from "./useLocalization";
import { FormFieldsVariables } from "../models/app";

const useFormFields = (params: FormFieldsVariables , bookingType: string) => {
  const { strings } = useLocalization();

  const bookingFields = (venue: any) => {
    const mealOptions: any[] = [
      {
        value: "Dinner",
        label: "Dinner",
      },
      {
        value: "Breakfast",
        label: "Breakfast",
      },
    ];

    const areas: any[] = venue ? venue.areas : [];
    let areaOptions: any[] = areas.map((area: any) => ({
      value: area,
      label: area,
    }));

    const timeSlots: any[] = venue ? venue.timeSlots : [];
    let timeSlotOptions: any[] = timeSlots.map((timeSlot: any) => ({
      value: timeSlot.id,
      label: timeSlot.name.split(" - ")[0],
    }));

    // If the booking type is not "Normal Booking", filter timeSlotOptions by specific ids
    if (bookingType !== "Normal Booking") {
      const allowedIds = [
        "84d1cecf-027f-4264-8960-e06cc4884fca",
        "253f3b7e-7a75-4c28-b7d0-1332cdaddfb7",
        "1f63f38d-1bdf-4845-835c-92053ce61ecf",
      ];
      timeSlotOptions = timeSlotOptions.filter((option: any) =>
        allowedIds.includes(option.value)
      );
    }


    return [
      {
        name: "pax",
        label: "Pax",
        placeholder: "Persons",
        type: "number",
        autoFocus: false,
        defaultValue: "",
      },
      {
        name: "date",
        label: "Date",
        placeholder: "Booking Date",
        type: "date",
        autoFocus: false,
        defaultValue: "",
      },
      {
        name: "time",
        label: "Time",
        type: "select",
        autoFocus: false,
        defaultValue:
          timeSlotOptions && timeSlotOptions.length > 0
            ? timeSlotOptions[0].value
            : "",
        options: timeSlotOptions,
      },
      {
        name: "area",
        label: "Area",
        type: "select",
        autoFocus: false,
        defaultValue:
          areaOptions && areaOptions.length > 0 ? areaOptions[0].value : "",
        options: areaOptions,
      },
      {
        name: "meal",
        label: "Meal",
        type: "select",
        autoFocus: false,
        defaultValue:
          mealOptions && mealOptions.length > 0 ? mealOptions[0].value : "",
        options: mealOptions,
      },
      {
        name: "coupon",
        label: "Coupon",
        placeholder: "Coupon",
        type: "InputBase",
        autoFocus: false,
        defaultValue: "",
      },
      {
        name: "note",
        label: "Note",
        placeholder: "General Note",
        type: "InputBase",
        autoFocus: false,
        defaultValue: "",
      },
    ];
  };

  const userInfoFields = () => {
    return [
      {
        name: "name",
        label: "Name",
        placeholder: "Full Name",
        type: "InputBase",
        autoFocus: true,
        defaultValue: "",
      },
      {
        name: "mobile",
        label: strings.mobile,
        placeholder: "01222222222",
        type: "phoneNumber",
        autoFocus: false,
        defaultValue: "",
      },
      {
        name: "email",
        label: strings.email,
        placeholder: "example@example.com",
        type: "InputBase",
        autoFocus: false,
        defaultValue: "",
      },
    ];
  };

  return {
    bookingFields,
    userInfoFields,
  };
};

export default useFormFields;
