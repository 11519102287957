import styled from "@emotion/styled";
import { Button, TextField, createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      light: "#241811",
      main: "#241811",
      dark: "#241811",
      contrastText: "#fff",
    },
    secondary: {
      light: "#E1C19E",
      main: "#E1C19E",
      dark: "#E1C19E",
      contrastText: "#fff",
    },
  },
});

export const StyledButton = styled(Button)(({ theme }) => ({
  color: "#E1C19E",
  backgroundColor: "transparent",
  borderColor: "#E1C19E",
  "&:hover": {
    backgroundColor: "#E1C19E",
    borderColor: "#E1C19E",
    color: "#fff",
  },
  "&.Mui-disabled": {
    background: "#transparent",
    color: "#c0c0c0",
    borderColor: "#c0c0c0",
  },
}));

export const StyledInput = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 0,
      borderColor: "#E1C19E",
    },
    "&:hover fieldset": {
      borderColor: "#E1C19E",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E1C19E",
    },
    "& input": {
      textAlign: "center",
      width: 60,
      color: "#fff",
    },
  },
});

/**
 * Capitalize first letter of a given string
 *
 * @param string string: string
 *
 * @returns string
 */
export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isDateInPast(givenDate: string): boolean {
  const currentDate = new Date();
  const inputDate = new Date(givenDate);

  // Convert both dates to UTC to avoid timezone issues
  currentDate.setUTCHours(0, 0, 0, 0);
  inputDate.setUTCHours(0, 0, 0, 0);

  return inputDate < currentDate;
}

export function getTimeSlotName(timeSlots: any[], id: string): string {
  let name = "";

  for (const timeSlot of timeSlots) {
    if (timeSlot.id === id) {
      name = timeSlot.name.split(" - ")[0];
      break;
    }
  }
  return name;
}
