import BookingComponent from "./components/BookingComponent";
import MainSnackbar from "./components/UI/MainSnackbar";
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";

function App() {
  const snackBarOpen = useSelector((state: any) => state.app.snackBarOpen);

  return (
    <>
      <Routes>
        <Route path="/*" element={<BookingComponent />} />
      </Routes>

      {snackBarOpen && <MainSnackbar />}
    </>
  );
}

export default App;
