import { ThemeOptions } from "@mui/material/styles";

const lightThemeOptions: ThemeOptions = {
  direction: "ltr",
  palette: {
    primary: {
      light: "#19100b",
      main: "#241811",
      dark: "#4f4640",
      contrastText: "#fff",
    },
    secondary: {
      light: "#9d876e",
      main: "#E1C19E",
      dark: "#e7cdb1",
      contrastText: "#fff",
    },
    background: {
      paper: "#fff",
      default: "#F9F9F9",
    },
  },
  shape: {
    borderRadius: 0,
  },
};

export default lightThemeOptions;
