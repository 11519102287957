import axios from "axios";
import { EndPoints } from "../constants/enums";

export const getVenues = async () => {
  try {
    const response = await axios.get(EndPoints.Venues);

    return response.data;
  } catch (error) {
    throw new Error("Error retrieving venues");
  }
};

export const getTimeSlots = async (conceptID: string) => {
  try {
    const response = await axios.get(EndPoints.TimeSlots + `?id=${conceptID}`);

    return response.data;
  } catch (error) {
    throw new Error("Error retrieving TimeSlots");
  }
};
