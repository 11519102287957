import LocalizedStrings from "react-localization";

const useLocalization = () => {
  let strings = new LocalizedStrings({
    en: {
      login: "Login",
      register: "Register",
      youdonthaveaccount: "You don't have an account yet?",
      email: "Email",
      password: "Password",
      forgetpassword: "Forgot your password?",
      haveaccount: "Already have an account",
      firstname: "First Name",
      middlename: "Middle Name",
      lastname: "Last Name",
      facebook: "Facebook Link",
      instagram: "Instagram Link",
      phonenumber: "Phone Number",
      mobile: "Mobile",
      whatsapp_phonenumber: "WhatsApp Number",
      birthdate: "Birth Date",
      resetpassword: "Reset Your password",
      verifyemail: "Verify your email to continue",
      code: "code",
      verify: "verify",
      sendResetMail: "send reset email",
      dashboard: "dashboard",
      coupon: "coupon",
    },
    ar: {
      login: "تسجيل الدخول",
      register: "اشتراك",
      youdonthaveaccount: "ليس لديك حساب؟",
      email: "البريد الإلكتروني",
      password: "كلمه السر",
      forgetpassword: "هل نسيت كلمة السر؟",
      haveaccount: "هل لديك حساب؟",
      firstname: "الاسم الاول",
      middlename: "الاسم الوسطى",
      lastname: "اسم العائلة",
      facebook: "رابط الفيسبوك",
      instagram: "رابط الانستغرام",
      phonenumber: "رقم الهاتف",
      mobile: "رقم الهاتف",
      whatsapp_phonenumber: "رقم هاتف whatsapp",
      birthdate: "تاريخ الميلاد",
      resetpassword: "إعادة تعيين كلمة المرور",
      verifyemail: "تحقق من بريدك الإلكتروني للمتابعة",
      code: "رمز",
      verify: "تحقق",
      dashboard: "لوحة القيادة",
      courses: "الدورات",
      slides: "الشرائح",
      users: "المستخدمين",
      pages: "الصفحات",
      questions: "أسئلة",
      quizzies: "الإختبارات",
      modules: "الوحدات",
      newpage: "صفحة جديدة",
      newslide: "سلايد جديدة",
      densepadding: "حشوة كثيفة",
      logout: "تسجيل الخروج",
      startquiz: "بدء الاختبار",
      getintouch: "ابق على تواصل معنا",
      submit: "إرسال",
      save: "حفظ",
      update: "تحديث",
      address: "عنوان",
      phone: "هاتف",
      yourname: "اسمك",
      youremail: "بريدك الالكتروني",
      subject: "موضوع",
      yourmessage: "رسالتك",
      totalstudents: "مجموع الطلاب",
      totalmodules: "مجموع الوحدات",
      totalquizzies: "إجمالي الاختبارات",
      totalquestions: "مجموع الأسئلة",
      newcourse: "دورة جديدة",
      back: "عودة",
      draganddrop: `قم بسحب "وإسقاط" بعض الملفات هنا ، أو انقر لتحديد الملفات`,
      drag: `قم بسحب "وإسقاط" بعض الملفات هنا`,
      thumbnail: "صورة الغلاف",
      createdby: "انشأ من قبل",
      createdat: "أنشئت في",
      lastupdate: "اخر تحديث",
      searchcourses: "البحث في الدورات...",
      coursename: "اسم الدورة التدريبية",
      selected: "مختار",
      coursestudents: "طلاب الدورة",
      coursemodules: "وحدات الدورة",
      nocourses: "لا توجد دورات بعد!",
      name: "اسم",
      nocoursestudents: "لا يوجد طلاب مسجلين في هذه الدورة بعد!",
      dropfiles: "قم بإسقاط الملفات هنا ...",
      actions: "أجراءات",
      modulename: "اسم وحدة",
      searchcoursemodules: "البحث في وحدات الدورة ...",
      nocoursemodules: "لا توجد وحدات في هذه الدورة بعد!",
      addcoursemodules: "إضافة وحدات إلى الدورة",
      questionnumber: "السؤال رقم",
      true: "صواب",
      false: "خطاْ",
      correctanswer: "اجابة صحيحة",
      wronganswer: "إجابة خاطئة",
      next: "التالي",
      previous: "السابق",
      tryagain: "حاول مرة أخرى",
      response: "إجابة",
      quizQuestions: "أسئلة الاختبار",
      searchQuizQuestions: "البحث عن أسئلة الاختبار",
      noQuizQuestions: "لم يتم إضافة أسئلة لهذا الاختبار حتى الآن!",
      noQuestions: "لا توجد أسئلة للاختيار من بينها!",
      addQuestionToSelectFrom: "أضف أسئلة للاختيار من بينها",
      addQuestionToQuiz: "أضف أسئلة لهذا الاختبار",
      nopages: "لا توجد صفحات بعد!",
      searchPages: "بحث عن صفحات ...",
      none: "لا يوجد",
      childof: "طفل",
      pagetitle: "عنوان الصفحة",
      slug: "رابط",
      content: "المحتوى",
      excerpt: "مقتطفات",
      protected: "محمي",
      showInMenu: "إظهار في القائمة",
      menuPosition: "موقف القائمة",
      showInFeatured: "عرض في لافتة",
      showQuiz: "اختبار لعرضها في هذه الصفحة",
      newuser: "مستخدم جديد",
      usersSearch: "البحث عن المستخدمين ...",
      fullname: "الاسم الكامل",
      role: "دور",
      profileimage: "صورة الملف الشخصي",
      title: "عنوان",
      newmodule: "وحدة جديدة",
      searchmodules: "البحث عن وحدات ...",
      contentisrequired: "المحتوى مطلوب",
      moduleupdated: "تم تحديث الوحدة بنجاح",
      questionTypes: "أنواع الأسئلة",
      questionType: "نوع السؤال",
      questionTitle: "عنوان السؤال",
      searchquestions: "البحث عن الأسئلة ...",
      newquestion: "سؤال جديد",
      selectQustionType: "حدد نوع السؤال",
      quiz: "اختبار",
      questionimage: "صورة السؤال",
      questionoptions: "خيارات السؤال",
      optiontext: "نص الخيار",
      optionlabel: "تسمية الخيار",
      optionImage: "صورة الخيار",
      newquiz: "اختبار جديد",
      searchquizzes: "البحث عن الاختبارات ...",
      description: "وصف",
      viewmore: "عرض المزيد",
      browsesite: "تصفح الموقع",
      virtualRealityFile: "ملف الواقع الافتراضي",
      nextquiz: "الاختبار التالي",
      prerequisite: "المتطلبات المسبقة",
      submitanswers: "إرسال الإجابات",
      congurations: "تهنئة",
      forcompleting: "لاستكمال",
      yourscore: "درجاتك",
      proceedtonext: "انتقل إلى الاختبار التالي",
      youneedtocomplete: "تحتاج إلى إكمال",
      beforeattempting: "قبل محاولة",
      complete: "إكمال",
      youdidntsucceed: "لم تنجح في",
      nextcourse: "الدورة القادمة",
      showCourse: "دوره لعرضها في الصفحة",
      proceedtotheoreticalcontent: "انتقل إلى المحتوى النظري",
      prerequisitequiz: "اختبار متطلب",
      prerequisitemodule: "وحدة متطلب",
      nextmodule: "الوحدة التالية",
      completemodule: "إكمال وحدة",
      proceedtonextmodule: "انتقل إلى الوحدة التالية",
      showvrcontent: "عرض محتوى الواقع الافتراضي",
      exitvrcontent: "الخروج من محتوى الواقع الافتراضي",
      coursedesc: "وصف الدورة التدريبية",
      parent: "الأبوين",
      achievements: "إنجازات",
      statistics: "هنا يمكنك مراجعة بعض الإحصائيات ",
      mobilecover: "صورة غلاف المحمول",
      vrtheatre: "مسرح الواقع الافتراضي",
      instructions: "التعليمات",
      supervisors: "المشرفين والباحثة",
      home: "الرئيسية",
      userguide: "دليل استخدام",
      contactus: "اتصل بنا",
      thecontent: "المحتوى",
      achievementtest: "الاختبار التحصيلى",
      visualtest: "اختبار مهارات التفكير البصرى",
      motivationscale: "مقياس الدافعية للانجاز",
      thereticalcontent: "المحتوى النظرى",
      practicalcontent: "المحتوى العملى",
      medialibrary: "مكتبة الوسائط",
      addnew: "اضف جديد",
      uploadnewmedia: "تحميل وسائط جديدة",
      or: "أو",
      selectfiles: "اختر الملفات",
      filename: "اسم الملف",
      noquestions: "لا توجد أسئلة حتى الآن!",
      noquestionsTypes: "لا توجد أنواع أسئلة حتى الآن!",
      nomodules: "لا توجد وحدات بعد!",
      noquizzes: "لا توجد اختبارات بعد!",
      ordernumber: "ترتيب",
      posmoduletest: "اختبار ما بعد الوحدة",
      coupon: "للخصم",
    },
  });

  strings.setLanguage("en");

  return { strings };
};

export default useLocalization;
